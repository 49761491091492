body {
  margin: 0px 0px 0px 1cm;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:.7em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2{ 
  text-align: left;
  letter-spacing: 2px;
  font-size: 2em;
  margin: 2px auto; 
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

tr.first > th {
  background-color:white;
} 

th {
  background-color:#e2efda;
  height:2em;
  text-align:center;
}

td {
 text-align:center;
}

td.num{
  color:#70AD47;
}

td.payday{
  color:red;
}

td.fee{
  color:#2F75B5;
}

td.name{
  font-size:1.1em;
}

td.violinOnly{
  color:#70AD47;
}

td.violinAttend {
  color:#70AD47;
  /*border-right-style: none;*/
}

td.t1{
  color:red;
}
td.t4{
  color:#2F75B5;
}
td.t2{
  color:black;
}
td.t3{
  color:#70AD47;
}
td.t5{
  color:#005db5;
}
td.t0{
  text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF;
}
td.null{
  background-color:rgb(183, 192, 201);
} 
td.rightBorderRed{
  border-right:2px solid red;
} 
tr.gap{
  background-color:rgb(255, 241, 253);
  height:19px;
}

button {
  margin: 10px 2px;
  border-radius: 5px;
  border-width:0px;
}

button.yellow {
  background-color:rgb(255, 255, 0);
}

button.gray {
  background-color:rgb(235, 235, 235);
}

@media print {
.noPrint {
  display: none;
}
}